// extracted by mini-css-extract-plugin
export var Group = "ArtificialIntelligence-module--Group--0911a";
export var Vector = "ArtificialIntelligence-module--Vector--755d8";
export var card = "ArtificialIntelligence-module--card--ac6ec";
export var cardWrapper = "ArtificialIntelligence-module--cardWrapper--51579";
export var choseImg = "ArtificialIntelligence-module--choseImg--a814f";
export var cir = "ArtificialIntelligence-module--cir--9b221";
export var description = "ArtificialIntelligence-module--description--30cd2";
export var dots1 = "ArtificialIntelligence-module--dots1--6328d";
export var dots2 = "ArtificialIntelligence-module--dots2--f0be9";
export var dots4 = "ArtificialIntelligence-module--dots4--c1eba";
export var dotscard = "ArtificialIntelligence-module--dotscard--9ce0e";
export var heading = "ArtificialIntelligence-module--heading--8f837";
export var react = "ArtificialIntelligence-module--react--d581b";
export var subservice = "ArtificialIntelligence-module--subservice--bedd2";
export var teamBtn = "ArtificialIntelligence-module--teamBtn--b85c6";
// extracted by mini-css-extract-plugin
export var bannerBtn = "DevelopmentProcess2-module--bannerBtn--40565";
export var btn_white1_border = "DevelopmentProcess2-module--btn_white1_border--d414a";
export var card = "DevelopmentProcess2-module--card--df87b";
export var cardWrapper = "DevelopmentProcess2-module--cardWrapper--42069";
export var description = "DevelopmentProcess2-module--description--b128f";
export var dots5 = "DevelopmentProcess2-module--dots5--a2e57";
export var heading = "DevelopmentProcess2-module--heading--a2ad9";
export var hex = "DevelopmentProcess2-module--hex--e09c5";
export var peakNumber = "DevelopmentProcess2-module--peakNumber--219c9";
export var react = "DevelopmentProcess2-module--react--33324";
export var serial2 = "DevelopmentProcess2-module--serial2--cc488";
export var subservice = "DevelopmentProcess2-module--subservice--585f6";
export var teamBtn = "DevelopmentProcess2-module--teamBtn--96a25";
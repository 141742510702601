// extracted by mini-css-extract-plugin
export var SliderWidth = "PartnerWith2-module--SliderWidth--dd4b1";
export var arrowImg = "PartnerWith2-module--arrowImg--02703";
export var card = "PartnerWith2-module--card--deaa1";
export var cardWrapper = "PartnerWith2-module--cardWrapper--caa9a";
export var cards = "PartnerWith2-module--cards--b29a3";
export var description = "PartnerWith2-module--description--63084";
export var fineBg = "PartnerWith2-module--fineBg--8870c";
export var heading = "PartnerWith2-module--heading--ecc26";
export var headingPremium = "PartnerWith2-module--headingPremium--62c19";
export var iconContainer = "PartnerWith2-module--iconContainer--fa7f9";
export var iconContainerLeft = "PartnerWith2-module--iconContainerLeft--b3b67";
export var portfolioArrowIcon = "PartnerWith2-module--portfolioArrowIcon--59e80";
export var portfolioArrowIconCover = "PartnerWith2-module--portfolioArrowIconCover--9e352";
export var portfolioArrowRightIconCover = "PartnerWith2-module--portfolioArrowRightIconCover--aee5f";
export var premiumBg = "PartnerWith2-module--premiumBg--66c52";
export var premiumImg = "PartnerWith2-module--premiumImg--13e03";
export var providingImg = "PartnerWith2-module--providingImg--d3f48";
export var teamButton = "PartnerWith2-module--teamButton--a5ad1";
export var teamWork = "PartnerWith2-module--teamWork--cab90";
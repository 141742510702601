// extracted by mini-css-extract-plugin
export var Frame = "Toolkit-module--Frame--e9b76";
export var Rec = "Toolkit-module--Rec--8e747";
export var advance = "Toolkit-module--advance--df11c";
export var cir = "Toolkit-module--cir--5dfe7";
export var description = "Toolkit-module--description--67577";
export var dots = "Toolkit-module--dots--a3a20";
export var for1 = "Toolkit-module--for1--7d22d";
export var heading = "Toolkit-module--heading--ccbb3";
export var iconssCard = "Toolkit-module--iconssCard--9ed24";
export var techBg = "Toolkit-module--techBg--71d88";
export var techDot = "Toolkit-module--techDot--a515b";
export var techIcon = "Toolkit-module--techIcon--f443f";
export var techImg = "Toolkit-module--techImg--e70eb";
export var technologyIcon = "Toolkit-module--technologyIcon--241cc";
import React from "react"
import { Link } from "gatsby"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import dots from "../../images/softwere-development/Group1.png"
import reactangel from "../../images/softwere-development/Vector.svg"
import * as styles from "./DevelopmentProcess2.module.scss"

const DevelopmentProcess2 = ({ strapiData }) => {
  return (
    <div className={`${styles.subservice}`}>
      <div className={styles.react}>
        <img
          src={reactangel}
          decoding="async"
          loading="lazy"
          alt="reactangel"
        />
      </div>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <div className={styles.dots5}>
          <img src={dots} decoding="async" loading="lazy" alt="dots" />
        </div>
        <Row className={`${styles.cardWrapper}`}>
          {strapiData?.cards?.map((e, i) => (
            <Col xl={4} md={6} xs={12} key={i}>
              <div className={styles.card}>
                <div className={styles.hex}>
                  <img
                    src={e?.image1[0]?.localFile?.publicURL}
                    decoding="async"
                    loading="lazy"
                    alt={e?.image1[0]?.alternativeText}
                  />
                </div>
                <div className={styles.peakNumber}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                    className={styles.serial2}
                  />
                  <h3>{e?.title}</h3>
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: e?.description?.description,
                  }}
                />
              </div>
            </Col>
          ))}
          <div className={`${styles.bannerBtn}`}>
            {strapiData?.buttons[0] && (
              <Link
                to={strapiData?.buttons[0]?.url}
                className={styles.btn_white1_border}
              >
                {strapiData?.buttons[0]?.title}
              </Link>
            )}
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default DevelopmentProcess2

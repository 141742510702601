import { graphql } from "gatsby"
import React from "react"
import Artificial from "../components/ai-development/ArtificialIntelligence"
import Banner from "../components/ai-development/Banner"
import Development from "../components/ai-development/DevelopmentProcess"
import DevelopmentProcess2 from "../components/ai-development/DevelopmentProcess2"
import HireAI from "../components/ai-development/HireAI"
import PartnerWith from "../components/ai-development/PartnerWith"
import PartnerWith2 from "../components/ai-development/PartnerWith2"
import Smarter from "../components/ai-development/SmarterFuture"
import Testimonials from "../components/ai-development/Testimonials"
import Toolkit from "../components/ai-development/Toolkit"
import WorkPortfolio from "../components/common/WorkPortfolio"
import ContactSales from "../components/common/ContactSales"
import EngagementModels from "../components/common/Engagement-Model/EngagementModels"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import Testimonials2 from "../components/healthcare/Testimonials2"
import MainLayout from "../layouts/MainLayout"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/ai_development_services_febce4a740.webp"
    />
  )
}
const aidevelopmentservices = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1281) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1281)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const artificial = data?.strapiPage?.sections[1]
  const partner = data?.strapiPage?.sections[2]
  const smarter = data?.strapiPage?.sections[3]
  const development = data?.strapiPage?.sections[4]
  const hireAI = data?.strapiPage?.sections[5]
  const engagement = data?.strapiPage?.sections[6]
  const toolkit = data?.strapiPage?.sections[7]
  const portfolio = data?.strapiPage?.sections[8]
  const faqs = data?.strapiPage?.sections[10]
  const testimonials = data?.strapiPage?.sections[9]
  const development2 = data?.strapiPage?.sections[11]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      <Artificial strapiData={artificial} />
      {!isMobile && !isTablet ? (
        <PartnerWith strapiData={partner} />
      ) : (
        <PartnerWith2 strapiData={partner} />
      )}
      <Smarter strapiData={smarter} pageName="Ai Development Services" />
      {!isMobile && !isTablet ? (
        <Development strapiData={development} />
      ) : (
        <DevelopmentProcess2 strapiData={development2} />
      )}

      <HireAI strapiData={hireAI} />
      <EngagementModels strapiData={engagement} />
      <Toolkit strapiData={toolkit} />
      <WorkPortfolio strapiData={portfolio} />
      {!isMobile && !isTablet ? (
        <Testimonials strapiData={testimonials} />
      ) : (
        <Testimonials2 strapiData={testimonials} />
      )}
      <Faqs strapiData={faqs} pageName="Ai Development Services" />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query mobileAppDevelopments {
    strapiPage(slug: { eq: "ai-revamp" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default aidevelopmentservices

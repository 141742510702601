import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./PartnerWith.module.scss"

const PartnerWith = ({ strapiData }) => {
  return (
    <div className={`${styles.subservice}`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className={`${styles.cardWrapper}`}>
          {strapiData?.cards?.map((e, i) => (
            <div className={styles.card} key={i}>
              <img
                className={styles.hoverImg}
                src={e?.image1[0]?.localFile?.publicURL}
                decoding="async"
                loading="lazy"
                alt={e?.title}
              />
              <h3>{e?.title}</h3>
              <p
                className={styles.cardDec}
                dangerouslySetInnerHTML={{
                  __html: e?.subTitle,
                }}
              />
            </div>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default PartnerWith

// extracted by mini-css-extract-plugin
export var clientDots = "Testimonials-module--clientDots--7e26e";
export var clientDots2 = "Testimonials-module--clientDots2--6eebc";
export var content = "Testimonials-module--content--c0ed0";
export var dots3 = "Testimonials-module--dots3--b1ede";
export var hireArrow = "Testimonials-module--hireArrow--4fbe6";
export var iconContainer = "Testimonials-module--iconContainer--15534";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--96cdd";
export var imgBtn = "Testimonials-module--imgBtn--807b9";
export var person = "Testimonials-module--person--58cc7";
export var personBg = "Testimonials-module--personBg--d02d2";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--31e56";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--64326";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--66cbb";
export var row = "Testimonials-module--row--9a17c";
export var section = "Testimonials-module--section--9133c";
export var staffRec = "Testimonials-module--staffRec--e764a";
export var testName = "Testimonials-module--testName--435ac";
export var testimonials = "Testimonials-module--testimonials--ee269";
export var testimonialsContent = "Testimonials-module--testimonialsContent--f188b";
export var vector2 = "Testimonials-module--vector2--d1c4c";
// extracted by mini-css-extract-plugin
export var bannerBtn = "HireAI-module--bannerBtn--fcdc9";
export var bannerBtn2 = "HireAI-module--bannerBtn2--cebe1";
export var btn_white1_border = "HireAI-module--btn_white1_border--731ec";
export var cards = "HireAI-module--cards--8fe83";
export var description = "HireAI-module--description--5cdaa";
export var hire = "HireAI-module--hire--3fd4e";
export var hireCyber = "HireAI-module--hireCyber--9f527";
export var hireImg = "HireAI-module--hireImg--48300";
export var talent = "HireAI-module--talent--d426a";
export var text = "HireAI-module--text--b1301";
export var tick = "HireAI-module--tick--14744";
// extracted by mini-css-extract-plugin
export var Frame = "DevelopmentProcess-module--Frame--c5056";
export var Ideation = "DevelopmentProcess-module--Ideation--82e57";
export var arrow2 = "DevelopmentProcess-module--arrow2--69591";
export var bannerBtn = "DevelopmentProcess-module--bannerBtn--fb3fa";
export var btn_white1_border = "DevelopmentProcess-module--btn_white1_border--223c0";
export var cards = "DevelopmentProcess-module--cards--58c73";
export var cards2 = "DevelopmentProcess-module--cards2--10fa2";
export var cardsData = "DevelopmentProcess-module--cardsData--0aae5";
export var cir = "DevelopmentProcess-module--cir--526d5";
export var circle = "DevelopmentProcess-module--circle--a4849";
export var closeUp = "DevelopmentProcess-module--closeUp--ed5a9";
export var dec = "DevelopmentProcess-module--dec--ccd94";
export var dots = "DevelopmentProcess-module--dots--96fc4";
export var dots2 = "DevelopmentProcess-module--dots2--3d0ed";
export var heading = "DevelopmentProcess-module--heading--f2b36";
export var leftText = "DevelopmentProcess-module--leftText--e3921";
export var mobBg = "DevelopmentProcess-module--mobBg--6c915";
export var number = "DevelopmentProcess-module--number--d2517";
export var number2 = "DevelopmentProcess-module--number2--db3c2";
export var peakNumber = "DevelopmentProcess-module--peakNumber--1e7b3";
export var peakNumber2 = "DevelopmentProcess-module--peakNumber2--ad068";
export var rec = "DevelopmentProcess-module--rec--0e862";
export var rightText = "DevelopmentProcess-module--rightText--c92b5";
export var serial2 = "DevelopmentProcess-module--serial2--50a96";
export var step = "DevelopmentProcess-module--step--a07d3";